import React, { useEffect, useRef, useState } from "react"
import { Link } from 'gatsby'
import qs from 'qs'

import {
  STRIPE_MESSAGE_QTY_MIN,
  STRIPE_PLAN_SENDER_PAYS
} from '~constants';

import Layout from "~components/layout/layout.component"
import PaymentForm from '~components/payment-form/payment-form.component'
import { Elements } from '~components/stripe-element/stripe-element.component'
import SEO from "~components/seo/seo.component"

import useStyles from '~components/purchase-messages/purchase-messages.styles';

const PurchaseMessagesPage = props => {
  const classes = useStyles(props);

  // Store references which are populated when the component is mounted.  SSR does not have window until
  // after the component has mounted.
  let url = useRef();
  let querystring = useRef();


  // Current organization vitals.
  const [organizationHash, setOrganizationHash] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [estimatedMessages, setEstimatedMessages] = useState(null);

    // Set initial values based on querystring parameters if they exist
    useEffect(() => {
      // Grab the original link href, parse the querystring.
      url.current = new URL(window.location.href).search;
      querystring.current = qs.parse(url.current, { ignoreQueryPrefix: true });
  
      if (querystring && querystring.current.a) {
        setOrganizationId(querystring.current.a);
      }
  
      if (querystring && querystring.current.h) {
        setOrganizationHash(querystring.current.h)
      }
  
      if (querystring && querystring.current.n) {
        setOrganizationName(querystring.current.n);
      }

      if (querystring && querystring.current.estimatedMessages) {
        setEstimatedMessages(parseInt(querystring.current.estimatedMessages));
      }
    }, [querystring, url])
  
  return (
  <Layout>
    <SEO title="Purchase Messages" />
    <h1>Purchase Messages</h1>
    <p>Use the form below to purchase additional messages for {organizationName || 'your organization'}.</p>
    {organizationId ? 
    <Elements>
      <PaymentForm
        className={classes.purchaseMessages}
        organizationHash={organizationHash}
        organizationId={organizationId}
        estimatedMessages={estimatedMessages ? Math.max(STRIPE_MESSAGE_QTY_MIN, estimatedMessages) : STRIPE_MESSAGE_QTY_MIN}
        selectedPlan={STRIPE_PLAN_SENDER_PAYS}
        onSuccess={() => {}}
        onError={() => {}}
      />
    </Elements> : null
    }
    <Link to="/">Go back to the homepage</Link>
  </Layout>
  )}
  
  export default PurchaseMessagesPage
  